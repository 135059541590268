<!--START TESTIMONIAL-->
<section class="section pt-4" id="team">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">Behind The People</h1>
        <div class="section-title-border mt-3"></div>
        <p class="section-subtitle text-muted text-center font-secondary pt-4">It is a long established fact that create category leading brand experiences a reader will be distracted by the readable content of a page when looking at its layout.</p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-3 col-sm-6">
        <div class="team-box text-center hover-effect">
          <div class="team-wrapper">
            <div class="team-member">
              <img alt="" src="assets/images/team/img-1.jpg" class="img-fluid rounded">
            </div>
          </div>
          <h4 class="team-name">Frank Johnson</h4>
          <p class="text-uppercase team-designation">CEO</p>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="team-box text-center hover-effect">
          <div class="team-wrapper">
            <div class="team-member">
              <img alt="" src="assets/images/team/img-2.jpg" class="img-fluid rounded">
            </div>
          </div>
          <h4 class="team-name">Elaine Stclair</h4>
          <p class="text-uppercase team-designation">Designer</p>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="team-box text-center hover-effect">
          <div class="team-wrapper">
            <div class="team-member">
              <img alt="" src="assets/images/team/img-3.jpg" class="img-fluid rounded">
            </div>
          </div>
          <h4 class="team-name">Wanda Arthur</h4>
          <p class="text-uppercase team-designation">Developer</p>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="team-box text-center hover-effect">
          <div class="team-wrapper">
            <div class="team-member">
              <img alt="" src="assets/images/team/img-4.jpg" class="img-fluid rounded">
            </div>
          </div>
          <h4 class="team-name">Joshua Stemple</h4>
          <p class="text-uppercase team-designation">Manager</p>
        </div>
      </div>

    </div>
  </div>
</section>
<!--END TESTIMONIAL-->

<!--START PROCESS-->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">Work Process</h1>
        <div class="section-title-border mt-3"></div>
        <p class="section-subtitle text-muted text-center font-secondary pt-4">In an ideal world this website
          wouldn’t exist, a client would acknowledge the importance of having web copy before the Proin vitae ipsum vel ex finibus semper design starts.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 text-center process-left-icon-1">
        <i class="pe-7s-angle-right"></i>
      </div>
      <div class="col-lg-6 text-center process-left-icon-2">
        <i class="pe-7s-angle-right"></i>
      </div>
      <div class="col-lg-6 text-center process-left-icon-3">
        <i class="pe-7s-angle-left"></i>
    </div>
    <div class="col-lg-6 text-center process-left-icon-4">
        <i class="pe-7s-angle-left"></i>
    </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-4 plan-line">
        <div class="text-center process-box">
          <i class="pe-7s-pen text-primary"></i>
          <h4 class="padding-t-15">Tell us what you need</h4>
          <p class="text-muted">The Big Oxmox advised her not to do so.</p>
        </div>
      </div>
      <div class="col-lg-4 plan-line">
        <div class="text-center process-box">
          <i class="pe-7s-id text-primary"></i>
          <h4 class="padding-t-15">Get free quotes</h4>
          <p class="text-muted">Little Blind Text didn’t listen.</p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="text-center process-box">
          <i class="pe-7s-target text-primary"></i>
          <h4 class="padding-t-15">Deliver high quality product</h4>
          <p class="text-muted">When she reached the first hills.</p>
        </div>
      </div>
      <div class="text-center mx-auto">
        <a href="javascript: void(0);" class="btn btn-primary waves-light waves-effect mt-5">Get Started <i
            class="mdi mdi-arrow-right"></i></a>
      </div>
    </div>
  </div>
</section>
<!--END PROCESS-->

<!--START TESTIMONIAL-->
<section class="section" id="testi">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">What they've said</h1>
        <div class="section-title-border mt-3"></div>
        <p class="section-subtitle text-muted text-center font-secondary pt-4">The Big Oxmox advised her not to do so, because there were thousands of bad Commas, wild Question Marks and devious pulvinar metus molestie sed Semikoli.</p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-4">
        <div class="testimonial-box hover-effect mt-4">
          <img src="assets/images/testimonials/user-2.jpg" alt=""
            class="img-fluid d-block img-thumbnail rounded-circle">
          <div class="testimonial-decs">
            <p class="text-muted text-center mb-0">“I feel confident imposing change on myself. It's a lot more fun progressing than looking back. That's why scelerisque pretium dolor, sit amet vehicula erat pelleque need throw curve balls.” </p>
          </div>
          <h5 class="text-center text-uppercase pt-3">Ruben Reed - <span
              class="text-muted text-capitalize">Charleston</span></h5>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="testimonial-box hover-effect mt-4">
          <img src="assets/images/testimonials/user-1.jpg" alt=""
            class="img-fluid d-block img-thumbnail rounded-circle">
          <div class="testimonial-decs">
            <p class="text-muted text-center mb-0">“Our task must be to free ourselves by widening our circle of compassion to embrace all living creatures Integer varius lacus non magna tempor congue natuasre the whole its beauty.” </p>
          </div>
          <h5 class="text-center text-uppercase pt-3">Michael P. Howlett - <span
              class="text-muted text-capitalize">Worcester</span></h5>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="testimonial-box hover-effect mt-4">
          <img src="assets/images/testimonials/user-3.jpg" alt=""
            class="img-fluid d-block img-thumbnail rounded-circle">
          <div class="testimonial-decs">
            <p class="text-muted text-center mb-0">“I've learned that people will forget what you said, people will forget what you did, but people will never aliquam in nunc quis tincidunt forget how you vestibulum egestas them feel.” </p>
          </div>
          <h5 class="text-center text-uppercase pt-3">Theresa D. Sinclair - <span
              class="text-muted text-capitalize">Lynchburg</span></h5>
        </div>
      </div>
    </div>
  </div>
</section>
<!--END TESTIMONIAL-->

<!--START GET STARTED-->
<section class="section section-lg bg-get-start">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2 text-center">
        <h1 class="get-started-title text-white">Let's Get Started</h1>
        <div class="section-title-border mt-4 bg-white"></div>
        <p class="section-subtitle font-secondary text-white text-center pt-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
        <a href="javascript: void(0);" class="btn btn-white waves-effect mt-3 mb-4">Get Started <i
            class="mdi mdi-arrow-right"></i> </a>
      </div>
    </div>
  </div>
  <div class="bg-pattern-effect bg-pattern-light">
    <!-- <img src="assets/images/bg-pattern-light.png" alt=""> -->
    <svg width="100%" height="43" viewBox="0 0 2002 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.49999V42L1998.5 40V23H1962L1879 33L1758.5 36L1703 29.5L1304.5 21.5C1285.3 15.5 1183.83 24 1135.5 29L636 35.5C455.2 1.89999 136.667 4.16665 0 9.49999Z" fill="white" fill-opacity="0.1" />
      <path opacity="0.1" d="M0 33.5V41.5L1997.5 42V27H1972.5C1585.3 -21.8 1403.17 6.66666 1360.5 27C1299.7 13.4 1035.17 6 910.5 4C856.1 -6.8 597.5 8.5 475 17.5L0 33.5Z" fill="white" />
      <path d="M0 31.6689V42.1689L2001.5 41.6689V11.6689L1942.25 18.3143C1910.49 21.8758 1879.21 29.7147 1847.38 32.5593C1799.52 36.8366 1750.41 29.3968 1727 23.6689C1577.8 -8.33114 1367.17 10.3355 1280.5 23.6689C1199.3 40.8689 1126.17 30.8355 1106 23.6689C1023.13 -2.89279 818.252 7.7814 689.669 19.2529C659.224 21.9691 629.271 29.2178 598.77 31.21C559.856 33.7516 520.953 28.0794 502 23.6689C395.2 -8.73115 122.833 15.1689 0 31.6689Z" fill="white" />
  </svg>
  </div>
</section>
<!--END GET STARTED-->
