<!-- Botão Flutuante do WhatsApp -->
<a href="https://api.whatsapp.com/send/?phone=5511976120604&text=Olá%2C%20gostaria%20de%20mais%20informações!" 
   class="whatsapp-float" target="_blank" aria-label="Enviar mensagem no WhatsApp">
  <i class="mdi mdi-whatsapp"></i>
</a>

<!-- INÍCIO DO FOOTER -->
<footer class="footer bg-custom-green text-dark pt-5 pb-4">
  <div class="container">
    <div class="row">
      <!-- Sobre a Empresa -->
      <div class="col-lg-3 col-md-6 mb-4">
        <h5 class="text-uppercase mb-3">Sobre Nós</h5>
        <p>
          A Adapton é uma empresa de inovação digital especializada em desenvolver soluções de software sob medida que impulsionam a transformação digital e fortalecem a presença online de negócios.
        </p>
      </div>
      
      <!-- Links Úteis -->
      <div class="col-lg-3 col-md-6 mb-4">
        <h5 class="text-uppercase mb-3">Links Úteis</h5>
        <ul class="list-unstyled">
          <li><a href="/politica-de-privacidade.html" class="footer-link">Política de Privacidade</a></li>
          <li><a href="/termos-de-uso.html" class="footer-link">Termos de Uso</a></li>
          <li><a href="https://api.whatsapp.com/send/?phone=5511976120604&text&type=phone_number&app_absent=0" class="footer-link">Contato</a></li>
        </ul>
      </div>
      
      <!-- Contato -->
      <div class="col-lg-3 col-md-6 mb-4">
        <h5 class="text-uppercase mb-3">Contato</h5>
        <ul class="list-unstyled">
          <a href="https://api.whatsapp.com/send/?phone=5511976120604&text&type=phone_number&app_absent=0" class="footer-link">
            <li class="mb-2"><i class="mdi mdi-phone"></i> +55 (11) 97612-0604</li>
          </a>
          <li class="mb-2"><i class="mdi mdi-email"></i> contato&#64;adapton.com.br</li>
        </ul>
      </div>
      
      <!-- Redes Sociais -->
      <div class="col-lg-3 col-md-6 mb-4">
        <h5 class="text-uppercase mb-3">Siga-nos</h5>
        <div class="d-flex">
          <a href="https://www.instagram.com/adaptonoficial" class="footer-social-link me-3" aria-label="Instagram">
            <i class="mdi mdi-instagram fs-4"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61560896743243" class="footer-social-link me-3" aria-label="Facebook">
            <i class="mdi mdi-facebook fs-4"></i>
          </a>
          <a href="https://api.whatsapp.com/send/?phone=5511976120604&text&type=phone_number&app_absent=0" class="footer-social-link me-3" aria-label="Twitter">
            <i class="mdi mdi-whatsapp fs-4"></i>
          </a>
        </div>
      </div>
    </div>
    
    <hr class="my-4">
    
    <div class="row">
      <div class="col-md-6">
        <p class="mb-0">&copy; {{ cur_year }} Adapton. Todos os direitos reservados.</p>
        <p class="mb-0">CNPJ: 55.229.749/0001-05 - Adapton Soluções em Negócios LTDA.</p>
      </div>
      <div class="col-md-6 text-md-end">
        <p class="mb-0">Desenvolvido por <a href="https://adapton.com.br" class="footer-link">Adapton</a>.</p>
      </div>
    </div>
  </div>
</footer>
<!-- FIM DO FOOTER -->
