<div class="account-home-btn d-none d-sm-block">
  <a href="/" class="text-white"><i class="mdi mdi-home h1"></i></a>
</div>

<section class="bg-account-pages vh-100">
  <div class="display-table">
    <div class="display-table-cell">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="card account-card">
              <div class="card-body">
                <div class="text-center mt-3">
                  <h3 class="fw-bold"><a href="/"
                      class="text-dark text-uppercase account-pages-logo">Dorsin</a></h3>
                  <p class="text-muted">Sign up for a new Account</p>
                </div>
                <div class="p-3">
                  <form>
                    <div class="form-group">
                      <label for="firstname">First Name</label>
                      <input type="text" class="form-control" id="firstname" placeholder="First Name">
                    </div>

                    <div class="form-group">
                      <label for="email">Email</label>
                      <input type="password" class="form-control" id="email" placeholder="Enter Email">
                    </div>

                    <div class="form-group">
                      <label for="userpassword">Password</label>
                      <input type="password" class="form-control" id="userpassword" placeholder="Enter password">
                    </div>

                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="customControlInline">
                      <label class="form-check-label" for="customControlInline">Remember me</label>
                    </div>

                    <div class="d-grid mt-3">
                      <button type="submit" class="btn btn-primary">Sign in</button>
                    </div>

                    <div class="mt-4 mb-0 text-center">
                      <p class="mb-0">Don't have an account ? <a routerLink="/login" class="text-danger">Sign in</a></p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>

  <!--START FOOTER ALTER-->
<div id="style-switcher" [style]="isVisible ? 'left: 0px' : 'left: -168px'">
  <div>
    <h3>Select your color </h3>
    <ul class="pattern">
      <li>
        <a (click)="setTheme('red')" class="color1" routerLink="#"></a>
      </li>
      <li>
        <a class="color2" (click)="setTheme('green')" routerLink="#"></a>
      </li>
      <li>
        <a class="color3" (click)="setTheme('pink')" routerLink="#"></a>
      </li>
      <li>
        <a class="color4" (click)="setTheme('cyan')" routerLink="#"></a>
      </li>
      <li>
        <a class="color5" (click)="setTheme('purple')" routerLink="#"></a>
      </li>
      <li>
        <a class="color6" (click)="setTheme('blue')" routerLink="#"></a>
      </li>
      <li>
        <a class="color7" (click)="setTheme('orange')" routerLink="#"></a>
      </li>
      <li>
        <a class="color8" (click)="setTheme('yellow')" routerLink="#"></a>
      </li>
    </ul>
  </div>
  <div class="bottom">
    <a href="javascript: void(0);" id="mode" class="mode-btn text-white" (click)="onChangeMode()">
      <i class="mdi mdi-white-balance-sunny mode-light align-middle"></i>
      <i class="mdi mdi-weather-sunny mode-dark align-middle"></i>
    </a>
    <a routerLink="#" class="settings" (click)="toggleSwitcher()"><i class="mdi mdi-cog  mdi-spin"></i></a>
  </div>
</div>
  
  
</section>
<!-- end account-pages  -->
