<!-- INÍCIO SOBRE NÓS -->
<section class="section bg-light" id="features">
  <div class="container">
    <div class="row align-items-center">
      <!-- Texto da Seção -->
      <div class="col-lg-6 col-md-12 mb-4 mb-lg-0">
        <div class="features-box text-center text-lg-start">
          <h3 class="text-success">Transformando Negócios e Pessoas através da Inovação Digital</h3>
          <p class="text-muted web-desc mt-3">
            Na <strong>Adapton</strong>, somos mais que desenvolvedores de software. Oferecemos soluções completas que incluem estratégias e marketing digital para promover a inclusão e expansão digital de negócios e indivíduos. Nosso objetivo é impulsionar a transformação digital, fortalecendo a presença online e aumentando a eficiência operacional de nossos clientes.
          </p>
          
          <!-- Tópicos Destacados -->
          <ul class="list-unstyled mt-4">
            <li class="d-flex align-items-start mb-3">
              <i class="mdi mdi-check-circle-outline text-success me-2 fs-5"></i>
              <div>
                <strong>Desenvolvimento de Software Sob Medida:</strong> Soluções personalizadas que atendem às necessidades específicas do seu negócio.
              </div>
            </li>
            <li class="d-flex align-items-start mb-3">
              <i class="mdi mdi-check-circle-outline text-success me-2 fs-5"></i>
              <div>
                <strong>Estratégias Digitais:</strong> Planejamento e execução de estratégias que maximizam sua presença online.
              </div>
            </li>
            <li class="d-flex align-items-start mb-3">
              <i class="mdi mdi-check-circle-outline text-success me-2 fs-5"></i>
              <div>
                <strong>Marketing Digital:</strong> Campanhas eficazes para aumentar o engajamento e as conversões.
              </div>
            </li>
            <li class="d-flex align-items-start">
              <i class="mdi mdi-check-circle-outline text-success me-2 fs-5"></i>
              <div>
                <strong>Inclusão Digital:</strong> Facilitamos a transição para o mundo digital, ampliando as oportunidades para negócios e pessoas.
              </div>
            </li>
          </ul>
        </div>
      </div>
      
      <!-- Imagem da Seção -->
      <div class="col-lg-6 col-md-12">
        <div class="features-img d-flex justify-content-center justify-content-lg-end">
          <img src="assets/images/develop.webp" alt="Imagem de desenvolvimento" class="img-fluid rounded shadow">
        </div>
      </div>
    </div>
  </div>

  <!-- Botão CTA Centralizado -->
  <div class="d-flex justify-content-center mt-4">
    <a href="https://api.whatsapp.com/send/?phone=5511976120604&text=Olá%2C%20gostaria%20de%20mais%20informações!" 
        class="btn-cta-whatsapp mt-4" target="_blank" aria-label="Chame-nos no WhatsApp">
      <i class="mdi mdi-whatsapp me-2"></i> Chame-nos no WhatsApp
    </a>
  </div>
</section>
<!-- FIM SOBRE NÓS -->
