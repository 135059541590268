<!-- CONTACT FORM START-->
<section class="section " id="contact">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">Fale conosco</h1>
        <div class="section-title-border mt-3"></div>
        <p class="section-subtitle text-muted text-center font-secondary pt-4">
          Nos destacamos ao idealizar soluções inovadoras e sabemos que uma boa ideia precisa ser sustentada por resultados tangíveis e impactantes. Convidamos você a entrar em contato conosco para transformar visões em realidade com estratégias que não apenas prometem, mas entregam sucesso mensurável.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="mt-4 pt-4">
          <p class="mt-4">
            <span class="h5">Nosso horários:</span><br> 
            <span class="text-muted d-block mt-2">
              09:00 até 18:00
            </span>
          </p>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="custom-form mt-4 pt-4">
          <form (ngSubmit)="sendMsg()" [formGroup]="itemData">
            <!-- Mensagens de erro e sucesso -->
            <div *ngIf="errorMsg" class="alert alert-danger error_message">
              {{ errorMsg }}
            </div>
            <div *ngIf="successMsg" class="alert alert-success error_message">
              {{ successMsg }}
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mt-2">
                  <input name="name" id="name" type="text" class="form-control" placeholder="Seu nome*" formControlName="name" required>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mt-2">
                  <input name="sobrenome" id="sobrenome" type="text" class="form-control" placeholder="Seu sobrenome*" formControlName="sobrenome" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mt-2">
                  <input name="email" id="email" type="email" class="form-control" placeholder="Seu email*" formControlName="email" required>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mt-2">
                  <input type="text" class="form-control" id="whatsapp" placeholder="Seu Whatsapp*" formControlName="whatsapp" required />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mt-2">
                  <input type="text" class="form-control" id="subject" placeholder="Assunto" formControlName="subject" required />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group mt-2">
                  <textarea name="comments" id="comments" rows="4" class="form-control" placeholder="Descreva o que você precisa" formControlName="comments" required></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-end">
                <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary" value="Enviar">
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Botão CTA Centralizado -->
  <div class="d-flex justify-content-center mt-4">
    <a href="https://api.whatsapp.com/send/?phone=5511976120604&text=Olá%2C%20gostaria%20de%20mais%20informações!" 
        class="btn-cta-whatsapp mt-4" target="_blank" aria-label="Chame-nos no WhatsApp">
      <i class="mdi mdi-whatsapp me-2"></i> Chame-nos no WhatsApp
    </a>
  </div>
</section>
<!-- CONTACT FORM END-->
<!--START SOCIAL-->
<section class="contact-social bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <ul class="list-inline social mt-4">
          <li class="list-inline-item">
            <a href="https://www.instagram.com/adaptonoficial" class="social-icon" target="_blank">
              <i class="mdi mdi-instagram"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://www.facebook.com/profile.php?id=61560896743243" class="social-icon" target="_blank">
              <i class="mdi mdi-facebook"></i>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://api.whatsapp.com/send/?phone=5511976120604&text&type=phone_number&app_absent=0" class="social-icon" target="_blank">
              <i class="mdi mdi-whatsapp"></i>
            </a>
          </li>
        </ul>     
      </div>
      <div class="col-lg-4 mt-4 text-end">
        <p class="contact-title"><i class="pe-7s-mail-open"></i>&nbsp; contato&#64;adapton.com.br</p>
      </div>
    </div>
  </div>
</section>
<!--END SOCIAL-->
