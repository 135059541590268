 <!-- START NAVBAR -->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
   id="navbar">
   <div class="container">
     <!-- LOGO -->
     <a class="navbar-brand logo text-uppercase" href="/">
       Dorsin
     </a>
     <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
       (click)="toggleMenu()" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
       <i class="mdi mdi-menu"></i>
     </button>
     <div class="collapse navbar-collapse" id="navbarCollapse">
       <ul class="navbar-nav navbar-center" id="mySidenav">
         <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
           <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
           <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Services</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
           <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link">Features</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
           <a [ngxScrollTo]="'#pricing'" href="javascript: void(0);" class="nav-link">Pricing</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'team'}">
           <a [ngxScrollTo]="'#team'" href="javascript: void(0);" class="nav-link">Team</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'blog'}">
           <a [ngxScrollTo]="'#blog'" href="javascript: void(0);" class="nav-link">Blog</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
           <a [ngxScrollTo]="'#contact'" href="javascript: void(0);" class="nav-link">Contact</a>
         </li>
       </ul>
       <div class="nav-button ms-auto">
         <ul class="nav navbar-nav navbar-end">
           <li>
             <button type="button" class="btn btn-primary navbar-btn btn-rounded">Try it
               Free</button>
           </li>
         </ul>
       </div>
     </div>
   </div>
 </nav>
 <!-- END NAVBAR -->
 <!--START HOME-->
 <section class="section section-lg vh-100 active" id="home"
   style="background-image: url('assets/images/img-1.jpg'); background-size: cover; background-position: center;">
   <div class="bg-overlay"></div>
   <div class="display-table">
     <div class="display-table-cell">
       <div class="container">
         <div class="row">
           <div class="col-lg-8 offset-lg-2 text-white text-center">
             <h1 id="foo" clas="home-title text-rotate">A Clean & Minimal Landing Template</h1> 
             <p class="pt-3 home-desc">Etiam sed.Interdum consequat proin vestibulum class at a euismod mus
               luctus quam.Lorem ipsum dolor sit amet, consectetur adipisicing eli.</p>
             <p class="play-shadow margin-t-30 margin-l-r-auto"><a (click)="openModal(content)"
                 class="play-btn video-play-icon"><i class="mdi mdi-play text-center"></i></a></p>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>
 <!--END HOME-->

<ng-template #content let-modal>
  <div class="modal-content home-modal">
   <div class="modal-header border-0">
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
   </div>
   <video id="VisaChipCardVideo" class="video-box" controls>
       <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
   </video>
  </div>
</ng-template>

 <div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
   <app-services></app-services>
   <app-features></app-features>
   <app-pricing></app-pricing>
   <app-team></app-team>
   <app-blog></app-blog>
   <app-contact></app-contact>
   <app-footer></app-footer>
 </div>
