import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica',
  templateUrl: './politica.component.html',
  styleUrls: ['./politica.component.css']
})
/**
 * Politica component
 */
export class PoliticaComponent implements OnInit {

  isVisible = false;
  
  constructor() { }

  ngOnInit(): void {}
}
