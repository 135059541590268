 <!-- STRAT NAVBAR -->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
   id="navbar">
   <div class="container">
     <!-- LOGO -->
     <a class="navbar-brand logo text-uppercase" href="/">
       Dorsin
     </a>
     <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
       aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
       <i class="mdi mdi-menu"></i>
     </button>
     <div class="collapse navbar-collapse" id="navbarCollapse">
       <ul class="navbar-nav navbar-center" id="mySidenav">
         <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
           <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
           <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Services</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
           <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link">Features</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
           <a [ngxScrollTo]="'#pricing'" href="javascript: void(0);" class="nav-link">Pricing</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'team'}">
           <a [ngxScrollTo]="'#team'" href="javascript: void(0);" class="nav-link">Team</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'blog'}">
           <a [ngxScrollTo]="'#blog'" href="javascript: void(0);" class="nav-link">Blog</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
           <a [ngxScrollTo]="'#contact'" href="javascript: void(0);" class="nav-link">Contact</a>
         </li>
       </ul>
       <div class="nav-button ms-auto">
         <ul class="nav navbar-nav navbar-end">
           <li>
             <button type="button" class="btn btn-primary navbar-btn btn-rounded">Try it
               Free</button>
           </li>
         </ul>
       </div>
     </div>
   </div>
 </nav>
 <!-- END NAVBAR -->
 <!--START HOME-->
 <section class="section bg-home vh-100 active" id="home">
   <div class="bg-overlay"></div>
   <div class="display-table">
     <div class="display-table-cell">
       <div class="container">
         <div class="row">
           <div class="col-lg-8 offset-lg-2 text-white text-center">
             <h1 class="home-title">We help startups launch their products</h1>
             <p class="padding-t-15 home-desc">Etiam sed.Interdum consequat proin vestibulum class at a euismod mus
               luctus quam.Lorem ipsum dolor sit amet, consectetur adipisicing eli.</p>
             <p class="play-shadow margin-t-30 margin-l-r-auto"><a (click)="openModal(content)"
                 class="play-btn video-play-icon"><i class="mdi mdi-play text-center"></i></a></p>
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="bg-pattern-effect">
     <!-- <img src="assets/images/bg-pattern.png" alt=""> -->
     <svg width="100%" height="43" viewBox="0 0 2002 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.49999V42L1998.5 40V23H1962L1879 33L1758.5 36L1703 29.5L1304.5 21.5C1285.3 15.5 1183.83 24 1135.5 29L636 35.5C455.2 1.89999 136.667 4.16665 0 9.49999Z" fill="white" fill-opacity="0.1" />
      <path opacity="0.1" d="M0 33.5V41.5L1997.5 42V27H1972.5C1585.3 -21.8 1403.17 6.66666 1360.5 27C1299.7 13.4 1035.17 6 910.5 4C856.1 -6.8 597.5 8.5 475 17.5L0 33.5Z" fill="white" />
      <path d="M0 31.6689V42.1689L2001.5 41.6689V11.6689L1942.25 18.3143C1910.49 21.8758 1879.21 29.7147 1847.38 32.5593C1799.52 36.8366 1750.41 29.3968 1727 23.6689C1577.8 -8.33114 1367.17 10.3355 1280.5 23.6689C1199.3 40.8689 1126.17 30.8355 1106 23.6689C1023.13 -2.89279 818.252 7.7814 689.669 19.2529C659.224 21.9691 629.271 29.2178 598.77 31.21C559.856 33.7516 520.953 28.0794 502 23.6689C395.2 -8.73115 122.833 15.1689 0 31.6689Z" fill="white" />
  </svg>
   </div>
 </section>

<ng-template #content let-modal>
  <div class="modal-content home-modal">
   <div class="modal-header border-0">
       <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
   </div>
   <video id="VisaChipCardVideo" class="video-box" controls>
       <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
   </video>
</div>
</ng-template>


 <!--END HOME-->
 <div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
   <app-services></app-services>
   <app-features></app-features>
   <app-pricing></app-pricing>
   <app-team></app-team>
   <app-blog></app-blog>
   <app-contact></app-contact>
   <app-footer></app-footer>
 </div>
