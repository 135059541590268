// src/app/shared/contact/contact.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root' // Garante que o serviço seja singleton e disponível globalmente
})
export class ContactService {
  private apiUrl = 'https://api.adapton.com.br'; // Substitua pela URL real da sua API

  constructor(private http: HttpClient) { }

  /**
   * Envia os dados de contato para a API.
   * @param contato Objeto contendo os dados do contato.
   * @returns Observable com a resposta da API.
   */
  addContato(contato: any): Observable<any> {
    // Ajuste a rota conforme a configuração da sua API
    return this.http.post<any>(`${this.apiUrl}/add-contato`, contato);
  }
}
