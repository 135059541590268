// src/app/shared/shared.module.ts

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HttpClientModule } from '@angular/common/http';

import { ServicesComponent } from './services/services.component';
import { PricingComponent } from './pricing/pricing.component';
import { FeaturesComponent } from './features/features.component';
import { TeamComponent } from './team/team.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    ServicesComponent,
    PricingComponent,
    FeaturesComponent,
    TeamComponent,
    BlogComponent,
    ContactComponent,
    ScrollspyDirective,
    FooterComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ScrollToModule,
    HttpClientModule
  ],
  exports: [
    ServicesComponent,
    PricingComponent,
    FeaturesComponent,
    TeamComponent,
    BlogComponent,
    ContactComponent,
    ScrollspyDirective,
    FooterComponent,
    CommonModule,
    ReactiveFormsModule,
    ScrollToModule,
    HttpClientModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
