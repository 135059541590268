// src/app/shared/contact/contact.component.ts

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactService } from './contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  itemData!: FormGroup;
  submitted = false;
  errorMsg: string = '';
  successMsg: string = '';

  constructor(private formBuilder: FormBuilder, private contactService: ContactService) { }

  ngOnInit(): void {
    // Inicializar o formulário com validação
    this.itemData = this.formBuilder.group({
      name: ['', [Validators.required]],
      sobrenome: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      whatsapp: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      comments: ['', [Validators.required]],
    });
  }

  // Validação do formulário
  ValidateForm() {
    const form = this.itemData;
    if (form.invalid) {
      this.errorMsg = "*Por favor, preencha todos os campos obrigatórios corretamente*";
      this.successMsg = '';
      return false;
    }
    this.errorMsg = '';
    return true;
  }

  // Função para enviar os dados do formulário
  sendMsg() {
    this.submitted = true;

    // Validar o formulário antes de enviar
    if (!this.ValidateForm()) {
      return;
    }

    // Coletar dados do formulário
    const contato = {
      nome: this.itemData.get('name')!.value,
      sobrenome: this.itemData.get('sobrenome')!.value,
      email: this.itemData.get('email')!.value,
      whatsapp: this.itemData.get('whatsapp')!.value,
      assunto: this.itemData.get('subject')!.value,
      descricao: this.itemData.get('comments')!.value
    };

    // Enviar requisição POST para o servidor usando o serviço
    this.contactService.addContato(contato)
      .subscribe({
        next: (response: any) => {
          if (response && response.success) {
            this.successMsg = "Contato enviado com sucesso!";
            this.errorMsg = '';
            this.itemData.reset(); // Limpa o formulário após o envio bem-sucedido
          } else {
            this.errorMsg = "Erro ao enviar contato. Tente novamente.";
            this.successMsg = '';
          }
        },
        error: (error) => {
          this.errorMsg = "Erro ao enviar contato. Tente novamente mais tarde.";
          this.successMsg = '';
          console.error('Erro ao enviar mensagem', error);
        }
      });
  }
}
