<!-- START NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()" id="navbar">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img src="assets/images/logo-horizontal-black.svg" height="40" alt="Logo"/>
    </a>
    <!-- BOTÃO TOGGLER PARA MOBILE -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" 
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" 
            (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <!-- MENU -->
    <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
      <ul class="navbar-nav" id="mySidenav">
        <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
          <a [ngxScrollTo]="'#home'" href="javascript:void(0);" class="nav-link">Início</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
          <a [ngxScrollTo]="'#services'" href="javascript:void(0);" class="nav-link">Serviços</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
          <a [ngxScrollTo]="'#features'" href="javascript:void(0);" class="nav-link">Sobre-nós</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
          <a [ngxScrollTo]="'#contact'" href="javascript:void(0);" class="nav-link">Contatos</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- END NAVBAR -->
<!--START HOME-->
<section class="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
  <div class="bg-overlay"></div>
  <div class="display-table">
    <div class="display-table-cell">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 text-white text-center">
            <h1 class="home-title">Transformando negócios para o sucesso online.</h1>
            <p class="padding-t-15 home-desc">Soluções de software personalizadas, adaptadas à sua estratégia de crescimento.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wave-effect wave-anim">
    <!-- <div class="waves-shape shape-one">
      <div class="wave wave-one">
        <svg width="2000" height="100" viewBox="0 0 2000 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M471 45C263 -22.2 71 18.6667 1 47.5V119H1999.5V34.5C1917.9 85.3 1823.83 68.3333 1787 53.5C1721.8 25.1 1603.17 9.33333 1552 4.99999C1366.4 -12.6 1172.67 34.3333 1099 60C1014.6 91.6 831.833 97.5 751 96.5C664.6 96.5 528.333 62.1667 471 45Z" fill="white" stroke="white" />
        </svg>
      </div>
    </div> -->
    <div class="waves-shape shape-two">
      <div class="wave wave-two">
      </div>
    </div>
    <div class="waves-shape shape-three">
      <div class="wave wave-three">
      </div>
    </div>
  </div>
</section>

<ng-template #content let-modal>
  <div class="modal-content home-modal">
    <div class="modal-header border-0">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <video id="VisaChipCardVideo" class="video-box" controls>
      <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4">
    </video>
  </div>
</ng-template>

<!--END HOME-->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <app-services></app-services>
  <app-features></app-features>
  <app-contact></app-contact>
  <app-footer></app-footer>
</div>