 <!-- STRAT NAVBAR -->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
   id="navbar">
   <div class="container">
     <!-- LOGO -->
     <a class="navbar-brand logo text-uppercase" href="/">
       Dorsin
     </a>
     <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
       (click)="toggleMenu()" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
       <i class="mdi mdi-menu"></i>
     </button>
     <div class="collapse navbar-collapse" id="navbarCollapse">
       <ul class="navbar-nav navbar-center" id="mySidenav">
         <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
           <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
           <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Services</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
           <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link">Features</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
           <a [ngxScrollTo]="'#pricing'" href="javascript: void(0);" class="nav-link">Pricing</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'team'}">
           <a [ngxScrollTo]="'#team'" href="javascript: void(0);" class="nav-link">Team</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'blog'}">
           <a [ngxScrollTo]="'#blog'" href="javascript: void(0);" class="nav-link">Blog</a>
         </li>
         <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
           <a [ngxScrollTo]="'#contact'" href="javascript: void(0);" class="nav-link">Contact</a>
         </li>
       </ul>
       <div class="nav-button ms-auto">
         <ul class="nav navbar-nav navbar-end">
           <li>
             <button type="button" class="btn btn-primary navbar-btn btn-rounded">Try it
               Free</button>
           </li>
         </ul>
       </div>
     </div>
   </div>
 </nav>
 <!-- END NAVBAR -->
 <!--START HOME-->
 <section class="section bg-home home-half" id="home">
   <div class="bg-overlay"></div>
   <div class="display-table">
     <div class="home-cell-bottom">
       <div class="container">
         <div class="row">
           <div class="col-lg-8 offset-lg-2 text-white text-center">
             <h1 class="home-title">We help startups launch their products</h1>
             <p class="pt-3 home-desc">Etiam sed.Interdum consequat proin vestibulum class at a euismod mus
               luctus quam.Lorem ipsum dolor sit amet, consectetur adipisicing eli.</p>
             <div class="text-center subscribe-form mt-5">
               <form action="#">
                 <input type="text" placeholder="Email">
                 <button type="submit" class="btn btn-primary">Subscribe</button>
               </form>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>
 <!--END HOME-->
 <div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
   <app-services></app-services>
   <app-features></app-features>
   <app-pricing></app-pricing>
   <app-team></app-team>
   <app-blog></app-blog>
   <app-contact></app-contact>
   <app-footer></app-footer>
 </div>
