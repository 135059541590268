<!--START SERVICES-->
<section class="section pt-5" id="services">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <h1 class="section-title text-center">Nossos Serviços</h1>
        <div class="section-title-border mt-3"></div>
        <p class="section-subtitle text-muted text-center pt-4 font-secondary">Nós desenvolvemos soluções de software inovadoras e estratégicas, criando experiências de marca líderes no segmento que agregam significado e valor para nossos clientes.</p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4 mt-3">
        <div class="services-box text-center hover-effect">
          <i class="pe-7s-tools text-primary"></i>
          <h4 class="pt-3">Desenvolvimento</h4>
          <p class="pt-3 text-muted">Criação personalizada de aplicativos web e móveis para atender necessidades específicas e melhorar a experiência do usuário.</p>
        </div>
      </div>
      <div class="col-lg-4 mt-3">
        <div class="services-box text-center hover-effect">
          <i class="pe-7s-config text-primary"></i>
          <h4 class="pt-3">Integração de Sistemas</h4>
          <p class="pt-3 text-muted">Conexão e otimização de sistemas existentes para uma operação empresarial mais eficiente e integrada.</p>
        </div>
      </div>
      <div class="col-lg-4 mt-3">
        <div class="services-box text-center hover-effect">
          <i class="pe-7s-cart text-primary"></i>
          <h4 class="pt-3">Soluções E-commerce</h4>
          <p class="pt-3 text-muted">Desenvolvimento de plataformas de vendas online completas, com suporte a transações seguras e gestão de inventário.</p>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-4 mt-3">
        <div class="services-box text-center hover-effect">
          <i class="pe-7s-science text-primary"></i>
          <h4 class="pt-3">Otimização de SEO</h4>
          <p class="pt-3 text-muted">Estratégias para melhorar a visibilidade do site nos motores de busca, atraindo mais tráfego e clientes potenciais.</p>
        </div>
      </div>
      <div class="col-lg-4 mt-3">
        <div class="services-box text-center hover-effect">
          <i class="pe-7s-display1 text-primary"></i>
          <h4 class="pt-3">Transformação Digital</h4>
          <p class="pt-3 text-muted">Implementação de tecnologias para digitalizar processos de negócios, aumentando eficiência e competitividade.</p>
        </div>
      </div>
      <div class="col-lg-4 mt-3">
        <div class="services-box text-center hover-effect">
          <i class="pe-7s-global text-primary"></i>
          <h4 class="pt-3">Consultoria</h4>
          <p class="pt-3 text-muted">Assessoria especializada para alinhar tecnologia e estratégia de negócios, impulsionando crescimento e inovação.</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Botão CTA Centralizado -->
  <div class="d-flex justify-content-center mt-4">
    <a href="https://api.whatsapp.com/send/?phone=5511976120604&text=Olá%2C%20gostaria%20de%20mais%20informações!" 
        class="btn-cta-whatsapp mt-4" target="_blank" aria-label="Chame-nos no WhatsApp">
      <i class="mdi mdi-whatsapp me-2"></i> Chame-nos no WhatsApp
    </a>
  </div>
</section>
<!--START SERVICES-->
