 <!--START PRICING-->
 <section class="section pt-5" id="pricing">
   <div class="container">
     <div class="row">
       <div class="col-lg-8 offset-lg-2">
         <h1 class="section-title text-center">Our Pricing</h1>
         <div class="section-title-border mt-3"></div>
         <p class="section-subtitle font-secondary text-muted text-center pt-4">Call to action pricing table is
           really crucial to your for your business website. Make your bids stand-out with amazing options.</p>
       </div>
     </div>
     <div class="row mt-5">
       <div class="col-lg-4">
         <div class="text-center pricing-box hover-effect">
           <h4 class="text-uppercase">Economy</h4>
           <h1>$9.90</h1>
           <h6 class="text-uppercase text-muted">Billing Per Month</h6>
           <div class="pricing-border"></div>
           <div class="plan-features mt-4">
             <p>Bandwidth: <b class="text-primary">1GB</b></p>
             <p>Onlinespace: <b class="text-primary">50MB</b></p>
             <p>Support: <b class="text-primary">No</b></p>
             <p><b class="text-primary">1</b> Domain</p>
             <p><b class="text-primary">No</b> Hidden Fees</p>
           </div>
           <a href="javascript: void(0);" class="btn btn-primary mt-4">Join Now</a>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="text-center pricing-box bg-white hover-effect price-active">
           <h4 class="text-uppercase">Deluxe</h4>
           <h1>$19.90</h1>
           <h6 class="text-uppercase text-muted">Billing Per Month</h6>
           <div class="pricing-border"></div>
           <div class="plan-features mt-4">
             <p>Bandwidth: <b class="text-primary">10GB</b></p>
             <p>Onlinespace: <b class="text-primary">500MB</b></p>
             <p>Support: <b class="text-primary">Yes</b></p>
             <p><b class="text-primary">10</b> Domain</p>
             <p><b class="text-primary">No</b> Hidden Fees</p>
           </div>
           <a href="javascript: void(0);" class="btn btn-primary mt-4">Join Now</a>
         </div>
       </div>
       <div class="col-lg-4">
         <div class="text-center pricing-box hover-effect">
           <h4 class="text-uppercase">Ultimate</h4>
           <h1>$29.90</h1>
           <h6 class="text-uppercase text-muted">Billing Per Month</h6>
           <div class="pricing-border"></div>
           <div class="plan-features mt-4">
             <p>Bandwidth: <b class="text-primary">100GB</b></p>
             <p>Onlinespace: <b class="text-primary">2 GB</b></p>
             <p>Support: <b class="text-primary">Yes</b></p>
             <p><b class="text-primary">Unlimited</b> Domain</p>
             <p><b class="text-primary">No</b> Hidden Fees</p>
           </div>
           <a href="javascript: void(0);" class="btn btn-primary mt-4">Join Now</a>
         </div>
       </div>
     </div>
   </div>
 </section>
 <!--ENd PRICING-->
