<header>
    <h1>Política de Privacidade - Adapton</h1>
</header>

<main>
    <section>
        <h2>1. Coleta de Informações</h2>
        <p>Ao utilizar nosso site, podemos coletar as seguintes informações:</p>
        <ul>
            <li><strong>Informações pessoais:</strong> como nome, e-mail e número de telefone, fornecidos voluntariamente através de formulários.</li>
            <li><strong>Dados de navegação:</strong> como endereço IP, localização geográfica, tipo de navegador, páginas visitadas e tempo gasto no site.</li>
        </ul>
        <p>Além disso, podemos coletar informações para campanhas de remarketing e marketing personalizado, que utilizamos em plataformas como Meta Ads e TikTok Ads.</p>
    </section>

    <section>
        <h2>2. Uso das Informações</h2>
        <p>As informações coletadas podem ser utilizadas para:</p>
        <ul>
            <li>Fornecer uma melhor experiência no site.</li>
            <li>Entrar em contato com você em relação a novidades, eventos ou mudanças em nossos serviços.</li>
            <li>Analisar e melhorar o desempenho do site.</li>
            <li>Realizar campanhas de marketing e remarketing, exibindo anúncios personalizados em plataformas como Meta Ads, Google Ads e TikTok Ads.</li>
            <li>Oferecer serviços ou informações relevantes, incluindo contato via WhatsApp com o uso de automação via bot, quando você autorizar.</li>
        </ul>
    </section>

    <section>
        <h2>3. Proteção das Informações</h2>
        <p>Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, alteração ou destruição. No entanto, nenhuma transmissão de dados pela Internet é totalmente segura, e não podemos garantir a segurança absoluta das informações.</p>
    </section>

    <section>
        <h2>4. Cookies</h2>
        <p>Nosso site utiliza cookies para melhorar a experiência do usuário. Os cookies são pequenos arquivos armazenados no seu dispositivo que ajudam a lembrar preferências e otimizar o desempenho do site.</p>
        <p>Utilizamos cookies para:</p>
        <ul>
            <li>Analisar e melhorar a navegação no site.</li>
            <li>Mostrar anúncios mais relevantes, incluindo anúncios exibidos pelo serviço Google AdSense, que usa cookies como o DoubleClick para personalizar as propagandas exibidas.</li>
            <li>Realizar remarketing, permitindo que exibamos anúncios em outras plataformas com base nas visitas anteriores ao nosso site.</li>
        </ul>
    </section>

    <section>
        <h2>5. Compartilhamento de Informações</h2>
        <p>Não vendemos, trocamos ou transferimos suas informações pessoais para terceiros, exceto:</p>
        <ul>
            <li>Quando necessário para parceiros confiáveis que nos auxiliam na operação do site, desde que essas partes concordem em manter as informações confidenciais.</li>
            <li>Quando exigido por lei.</li>
        </ul>
    </section>

    <section>
        <h2>6. Anúncios e Marketing</h2>
        <p>Utilizamos serviços de publicidade, como Facebook Ads, Google Ads, Meta Ads e TikTok Ads. Esses anúncios utilizam cookies de publicidade comportamental para garantir que você receba anúncios mais relevantes.</p>
        <p>Além disso, realizamos campanhas de remarketing, o que significa que podemos exibir anúncios com base nas suas interações anteriores com nosso site, oferecendo conteúdos personalizados em diferentes plataformas.</p>
    </section>

    <section>
        <h2>7. Links para Sites Externos</h2>
        <p>Nosso site pode conter links para sites externos que não são operados por nós. Não temos controle sobre o conteúdo e as práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.</p>
    </section>

    <section>
        <h2>8. Alterações na Política de Privacidade</h2>
        <p>Reservamo-nos o direito de atualizar esta política de privacidade a qualquer momento. Quaisquer alterações serão publicadas nesta página, e encorajamos você a revisá-la periodicamente para se manter informado sobre como estamos protegendo suas informações.</p>
    </section>

    <section>
        <h2>9. Consentimento</h2>
        <p>Ao utilizar nosso site, você concorda com nossa política de privacidade.</p>
    </section>

    <section>
        <h2>10. Contato</h2>
        <p>Se você tiver alguma dúvida sobre esta política de privacidade, entre em contato conosco através do e-mail: <a href="mailto:contato&#64;adapton.com.br">contato&#64;adapton.com.br</a></p>
    </section>
</main>

<footer>
    <p>&copy; 2024 Adapton. Todos os direitos reservados.</p>
    <ul>
        <li><a href="https://www.facebook.com/profile.php?id=61560896743243" target="_blank">Facebook</a></li>
        <li><a href="https://www.instagram.com/adaptonoficial" target="_blank">Instagram</a></li>
        <li><a href="https://api.whatsapp.com/send/?phone=5511976120604&text&type=phone_number&app_absent=0" target="_blank">WhatsApp</a></li>
    </ul>
</footer>